import React from 'react'
import './Testimonial.css'
import { testimonialsData } from '../data/testimonialsData'
import { useState } from 'react';
import leftArrow from '../assets/leftArrow.png'
import RightArrow from '../assets/rightArrow.png'

export default function Testimonials() {
    const[selected, setSelected] =useState(0);
    const tlength = testimonialsData.length;
    return (
    <div className='Testimonials'>
      <div className='left-t'>
        <span>Teams</span>
        <span className='stroke-text'>WHO ARE</span>
        <span>WE!</span>
        <span>
            {testimonialsData[selected].review}
        </span>
        <span>
            <span style = {{color:'var(--orange)'}}>
                {testimonialsData[selected].name} 
            </span>
            - {testimonialsData[selected].status}
        </span>
      </div>
      <div className='right-t'>
        <div></div>
        <div></div>
        <img src = {testimonialsData[selected].image} alt=" "/>
        <div className='arrows'>
            <img
            onClick={()=>{
                selected === 0
                ? setSelected(tlength -1)
                : setSelected((prev) => prev -1);
            }}
            src = {leftArrow} alt ="left"/>
            <img 
            onClick={()=>{
                selected === tlength -1
                ? setSelected(0)
                : setSelected((prev) => prev +1);
            }}
            src = {RightArrow} alt= "right"/>
        </div>
      </div>
    </div>
  )
}
