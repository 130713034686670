import React,  { useRef }  from 'react'
import './Become.css'
import emailjs from '@emailjs/browser';

export default function Become() {
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_yxu8reb', 'template_adwc0wg', form.current, {
        publicKey: 'x7Lrw2Gb4OdIjcE5G',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <div className='become' id ='become'>
        <div className='left-b'>
            <div>
                <span className='stroke-text'>Ready to</span>
                <span> Hire Best </span>
                <div>
                <span>Engineers</span>
                <span className='stroke-text'> with us?</span>
                </div>
            </div>
            
        </div>
        <div className='right-b'>
            <form ref = {form}action='' className='email-container' onSubmit={sendEmail}>
              <input className='email' placeholder='Enter Mail'></input>
              <button className='btn btn-b'>Join Us</button>
            </form>
            
        </div>
        
    </div>
  )
}
