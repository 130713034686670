import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";
import image4 from "../assets/t-image4.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "Frontend, Backend basically fullstack developer for The Kripple's Team !! Expertise : LLM, ReactJs, Designer",
    name: 'ANJILA SUBEDI ',
    status : ' FullStack/AI developer'
  },
  {
    image: image2,
    review: "Mostly App but also AI/Machine Learning Expert  for The Kripple's Team !! Expertise : LLM, Flutter",
    name: 'AAGAMAN BHATTARAI',
    status: 'Senior ML Engineer'
  },
  {
    image : image3,
    review:"LLM, Transformer Expert also Backend developer for The Kripple's Team !! Expertise : LLM, NLP, DeepLearning",
    name: 'Nawap Bastola',
    status: "Senior AI Engineer"
  },
  {
    image : image4,
    review:"Frontend, Backend basically fullstack developer for The Kripple's Team !! Expertise : CV, ReactJs, TypeScript",
    name: 'Siddartha Devkota',
    status: "Senior AI Engineer"
  }
];
