import React, { useState } from 'react'
import './Header.css'
import logo from '../assets/logo.png';
import bars from '../assets/bars.png';
import {Link} from "react-scroll";

export default function Header() {
  const mobile = window.innerWidth<=768 ? true: false;
  const [menuOpened,setMenuOpened] =useState(false)
  return (
    <div className='Header'>
      <img src={logo} alt="" className='logo'/>
      {menuOpened === false && mobile === true ?(
        <div
        style={{
          backgroundColor : 'var(--appColor)',
          padding : '0.5rem',
          borderRadius : '3px'
        }}

        onClick={()=> setMenuOpened(true)}
        >
          <img src = {bars} alt =''
          style = {{width : "1.5rem", height : "1.5rem"}}/>
        </div>
      ) : (
      <ul className='header-menu'>
        
        <li onClick={()=> setMenuOpened(false)}><Link
        to = "hero"
        span = {true}
        smooth = {true}>
        Home</Link></li>
        <li onClick={()=> setMenuOpened(false)}><Link
        to = "Product"
        span = {true}
        smooth = {true}>
          Products
        </Link></li>
        {/* <li onClick={()=> setMenuOpened(false)}>Solutions</li>
        <li onClick={()=> setMenuOpened(false)}>Pricing</li> */}
        <li onClick={()=> setMenuOpened(false)}><Link 
        to = "Testimonials"
        span = {true}
        smooth = {true}>
        Teams</Link></li>
      </ul>
      )}
    </div>
  )
}
