import React from 'react'
import './Final.css'
import linkdin from '../assets/linkedin.png'
import github from '../assets/github.png'
import instagram from '../assets/instagram.png'
import logo from '../assets/logo.png'


export default function Final() {
  return (
    <div className='Final'>
        <hr></hr>
        <div className='footer'>
        <div className='socials'>
        <img src={linkdin} alt=''></img>
        <img src={github} alt=''></img>
        <img src={instagram} alt=''></img>
      </div>
        <div className='logo1'>
            <img src={logo} alt=''></img>
        </div>
        </div>

        <div className='blur blur-f'></div>
        <div className='blur blur-f'></div>
      
    </div>
  )
}
