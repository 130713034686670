import React from 'react'
import './Product.css'
import { programsData } from '../data/programsData'

export default function Products() {
  return (
    <div className='Product' id ='products'>
      <div className='product_header'>
        <span className='stroke-text'>Grow Together</span>
        <span>With</span>
        <span className='stroke-text'>PlaceMe Daddy</span>
      </div>
      <div className='product-categories'>
        {programsData.map((program) => (
            <div className="category">
                {program.image}
                <span>{program.heading}</span>
                <span>{program.details}</span>
            </div>
        ))}
      </div>
    </div>
  )
}
