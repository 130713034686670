import React from 'react'
import './Hero.css'
import Header from './Header'
import image from '../assets/hero_image.png';
import image_back from '../assets/hero_image_back.png';

import {motion} from 'framer-motion';

export default function Hero() {
  const transition ={type : 'spring', duration : 3}
  const mobile = window.innerWidth<=768 ? true : false;
  return (
    <div className='hero'>
      <div className='blur blur-r'></div>
      <div className='left-h'>
        <Header/>
        <div className='best'>
          <motion.div
          initial = {{left : mobile? "178px":"238px" }}
          whileInView = {{left: '8px'}}
          transition = {{...transition, type: 'tween'}}>
        </motion.div>
          <span>Your interviews, our expertise – outsourcing excellence.</span>
        </div>
        <div className='bestext'>
          <div>
            <span>Hire</span>
            <span  className='stroke-text' > Best</span> 
          </div>
          <div><span>Engineers</span></div>
          <div>
            <span>
            PlaceMe Daddy is a cutting-edge platform revolutionizing the
hiring process with advanced CV parsing and interview
outsourcing solutions. Our technology seamlessly extracts and 
analyzes candidate information from resumes, 
streamlining the recruitment process for employers.
            </span>
          </div>
        </div>

        <div className='figures'>
          <div>
            <span>+1k</span>
            <span>
               Engineers
            </span>
          </div>
          <div>
            <span>+100</span>
            <span>
               Companies
            </span>
          </div>
          <div>
            <span>+50k</span>
            <span>
               Average Salary
            </span>
          </div>
        </div>

        <div className='bttn'>
          <div className='btn'>Request a Demo</div>
          <div className='btn'>Learn More</div>
        </div>
      </div>
      <div className='right-h'>
        <button className='btn'>Request a Demo</button>

        <img src = {image} alt = "" className='image' />
        <img src = {image_back} alt = "" className='image_back' />
      </div>
    </div>

  )
}
