import './App.css';
import Hero from './components/Hero';
import Products from './components/Products';
import Teams from './components/Testimonials';
import Become from './components/Become';
import Final from './components/Final';

function App() {
  return (
    <div className="App">
        <Hero/>
        <Products/>
        <Teams/>
        <Become/>
        <Final/>
    </div>
  );
}

export default App;
